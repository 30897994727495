import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import localforage from 'localforage';
import { gsap } from 'gsap';
import './Terms.css'; // Optional, for specific page styles
import float1 from '../assets/images/Icon_Chat_Light.png';
import float2 from '../assets/images/Icon_Message_Light.png';
import float3 from '../assets/images/Icon_Like_Dark.png';
import float4 from '../assets/images/Icon_Message_Dark.png';


const PageThree = () => {
        const [isChecked, setIsChecked] = useState(false);
        const [termsVariant, setTermsVariant] = useState('variant1');
        const navigate = useNavigate();
        const random = (min, max) => Math.random() * (max - min) + min;
        useEffect(() => {

            // Load the terms variant from localForage
            const loadTermsVariant = async() => {
                const savedTermsVariant = await localforage.getItem('termsVariant');
                if (savedTermsVariant) setTermsVariant(savedTermsVariant);
            };

            loadTermsVariant();

            // Fade in animation on page load
            gsap.fromTo(".page-content, .page-title, .page-footer", { opacity: 0 }, { opacity: 1, duration: 0.5 });
            const timeline = gsap.timeline();
            // Animate floating images into the screen with opacity
            timeline.fromTo(".chat-ligth-1", { opacity: 0 }, { opacity: 1, duration: 1.1, ease: "power1.in" }, 0);
            timeline.fromTo(".message-ligth-1", { opacity: 0 }, { opacity: 1, duration: 1.2, ease: "power1.in" }, 0);
            timeline.fromTo(".like-dark-1", { opacity: 0 }, { opacity: 1, duration: 1.3, ease: "power1.in" }, 0);
            timeline.fromTo(".like-dark-2", { opacity: 0 }, { opacity: 1, duration: 1.4, ease: "power1.in" }, 0);
            timeline.fromTo(".message-dark-1", { opacity: 0 }, { opacity: 1, duration: 1.5, ease: "power1.in" }, 0);
            // Continue smaller floating animations with random motion
            document.querySelectorAll(".floating-image").forEach((element) => {
                timeline.to(element, {
                    x: `+=${random(-20, 20)}px`,
                    y: `+=${random(-20, 20)}px`,
                    repeat: -1,
                    yoyo: true,
                    duration: random(3, 5),
                    ease: "power1.inOut"
                }, "-=1");
            });

        }, []);

        const handleCheckboxChange = () => {
            setIsChecked(!isChecked);
        };

        const handleSubmit = () => {
            // Fade out animation and then redirect

            gsap.to(".page-content, .page-title, .page-footer", {
                opacity: 0,
                duration: 0.5,
                onComplete: () => {
                    if (isChecked) {
                        navigate('/Slot');
                    } else {
                        navigate('/');
                    }
                }
            });
        };

        return ( <
                div className = "page-container" >
                <
                div className = 'page-header' >
                <
                h1 className = "page-title-small" > Terms & Conditions < /h1> <
                /div> <
                div className = "page-content" > {
                    termsVariant === 'variant1' ? ( <
                        div className = "terms-content" > { /* Terms Variant 1  Casablance*/ } <
                        p > CAMEL 2024 Lucky Draw - Terms and Conditions < /p> <
                        p >
                        1. Camel 2024 lucky draw is a promotion accessible only to existing adult smokers / users of tobacco or nicotine products(the“ Participants”) at the duty free shops in Casablanca airport during the period from July 1 st 2024 to September 30 th 2024. The Participants must have purchased at least two cartons of Camel cigarettes at one of the said duty free shops. <
                        /p> <
                        p >
                        2. The Participants can access the lucky draw only after a retail sales consultant or shop assistant activates the screen
                        for the lucky draw.At the end of each lucky draw session, it will be determined
                        if the Participant has won the item as JT International SA("JTI”) may decide from time to time (the “Grand Prize”). Any win must be validated by a retail sales consultant or shop assistant at the specific duty free shop. <
                            /p> <
                            p >
                            2.1 If the Grand Prize is a certain amount of hotel voucher:
                            Hotel vouchers are available
                            for purchase in 15 major currencies.The winner(s) can redeem the voucher in any currency as this is automatically converted on the website at the time of the booking.So, all vouchers can be purchased in US dollars
                            if needed.Bookings can be made 8 - 9 months in advance and are subject to hotel availability.Bookings are made on the website platform https: //www.hotelgift.com/redeem/. Vouchers are valid for 2 years. Multiple bookings can be made on the voucher, each booking reduces the balance on the voucher. If a booking is more expensive than the balance left on the voucher, the remaining amount shall be paid online by the winner(s) using a debit or a credit card.
                            <
                            /p> <
                            p >
                            2.2 If the Grand Prize is a certain amount of flight voucher:
                            Flight vouchers are available
                            for purchase in 15 major currencies.The winner(s) can redeem the voucher in any currency as this is automatically converted on the website at the time of the booking.So, all vouchers can be purchased in US dollars
                            if needed.Bookings can be made 8 - 9 months in advance and are subject to flight availability.Bookings are made on the website platform https: //www.flightgift.com/redeem/. Vouchers are valid for 2 years. Multiple bookings can be made on the voucher, each booking reduces the balance on the voucher. If a booking is more expensive than the balance left on the voucher, the remaining amount shall be paid online by the customer using a debit or a credit card.
                            <
                            /p> <
                            p >
                            2.3 The Grand Prize items are subject to availability.The Grand Prize is non - exchangeable, non - transferable and is not redeemable
                            for cash or other prizes.JTI is not responsible
                            for the quality of the Grand Prize nor any liabilities whatsoever associated with the Grand Prize.The winner(s) acknowledge that JTI shall have no responsibility
                            for change or cancellation of the flight tickets and / or the accommodations redeemed using the vouchers and the winner(s) will not be entitled to any compensation.The Grand Prize may incur taxes according to the winner 's country of residence. The winner(s) is responsible for covering all such payments, taxes and duties. In case a winner refuses to pay associated taxes and duties, the Grand Prize will not be provided, and an alternate prize will not be awarded. <
                            /p> <
                            p >
                            3. The winner(s) by accepting these Terms and Conditions acknowledge that JTI or its agency will use the winner(s)’ personal data(including full name and email address) for the purpose of execution of the Grand Prize only, this may also include passing on the data by JTI to JTI’ s agency that handles the Grand Prize.The winner(s) consent to the collection, use, storage, and disclosure of his / her personal data by JTI
                            for the purposes set out above.JTI and / or JTI’ s agency will not keep personal data of the winners
                            for longer than it is necessary
                            for the execution of the Grand Prize.The winner(s) understand that they may revoke their consent at any time.The Grand Prizes will be forfeited
                            if the winner(s) provides inaccurate personal data(including full name and email address) that results in failure of execution of the Grand Prize. <
                            /p> <
                            p >
                            4. Employees of JTI, Avolta, or Prestige SARL, the retail sales consultants, or any family members of a person that works directly or indirectly with them on organizing and running the Grand Prize are not eligible to be Participants. <
                            /p> <
                            p >
                            5. The Participants shall abide by all laws applicable in relation to the Grand Prize and shall indemnify and hold JTI harmless against any and all liabilities and claims incurred due to my violation of such applicable laws. <
                            /p> <
                            p >
                            6. The Participants acknowledge that the interest and good reputation of JTI is of paramount importance.JTI reserves the right to disqualify a Participant, or to withdraw the Grand Prize
                            if it believes the Participant is in breach of its obligations under these Terms and Conditions.The Participants shall not by act or omission, directly or indirectly bring JTI into disrepute. <
                            /p> <
                            p >
                            7. JTI reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, these Terms and Conditions with or without prior notice with no prior notice of its final and binding decision. <
                            /p> <
                            p >
                            8. JTI accepts no responsibility
                            for death, personal injury, loss, liabilities, injury or disappointment incurred or suffered by Participants because of entering the Grand Prize or accepting the Grand Prize. <
                            /p> <
                            p >
                            9. These Terms and Conditions shall be governed exclusively by the laws of Switzerland(without regard to any conflict of law rules that would cause the application of the laws of any other jurisdiction), to the exclusion of the United Nations Convention on the International Sale of Goods.Any dispute arising out of or in connection with these Terms and Conditions, whether of contractual or non - contractual nature, shall be subject to the exclusive jurisdiction of the courts of Geneva, Switzerland. <
                            /p> { /* ... other terms ... */ } <
                            /div>
                        ): ( <
                            div className = "terms-content" > { /* Terms Variant 2 */ } <
                            p > CAMEL 2024 Lucky Draw - Terms and Conditions < /p> <
                            p >
                            1. Camel 2024 lucky draw is a promotion accessible only to existing adult smokers / users of tobacco or nicotine products(the“ Participants”) at the duty free shops in Tunis airport during the period from July 1 st 2024 to September 30 th 2024. The Participants must have purchased at least Three cartons of Camel cigarettes at one of the said duty free shops. <
                            /p> <
                            p >
                            2. The Participants can access the lucky draw only after a retail sales consultant or shop assistant activates the screen
                            for the lucky draw.At the end of each lucky draw session, it will be determined
                            if the Participant has won the item as JT International SA("JTI”) may decide from time to time (the “Grand Prize”). Any win must be validated by a retail sales consultant or shop assistant at the specific duty free shop. <
                                /p> <
                                p >
                                2.1 If the Grand Prize is a certain amount of hotel voucher:
                                Hotel vouchers are available
                                for purchase in 15 major currencies.The winner(s) can redeem the voucher in any currency as this is automatically converted on the website at the time of the booking.So, all vouchers can be purchased in US dollars
                                if needed.Bookings can be made 8 - 9 months in advance and are subject to hotel availability.Bookings are made on the website platform https: //www.hotelgift.com/redeem/. Vouchers are valid for 2 years. Multiple bookings can be made on the voucher, each booking reduces the balance on the voucher. If a booking is more expensive than the balance left on the voucher, the remaining amount shall be paid online by the winner(s) using a debit or a credit card.
                                <
                                /p> <
                                p >
                                2.2 If the Grand Prize is a certain amount of flight voucher:
                                Flight vouchers are available
                                for purchase in 15 major currencies.The winner(s) can redeem the voucher in any currency as this is automatically converted on the website at the time of the booking.So, all vouchers can be purchased in US dollars
                                if needed.Bookings can be made 8 - 9 months in advance and are subject to flight availability.Bookings are made on the website platform https: //www.flightgift.com/redeem/. Vouchers are valid for 2 years. Multiple bookings can be made on the voucher, each booking reduces the balance on the voucher. If a booking is more expensive than the balance left on the voucher, the remaining amount shall be paid online by the customer using a debit or a credit card.
                                <
                                /p> <
                                p >
                                2.3 The Grand Prize items are subject to availability.The Grand Prize is non - exchangeable, non - transferable and is not redeemable
                                for cash or other prizes.JTI is not responsible
                                for the quality of the Grand Prize nor any liabilities whatsoever associated with the Grand Prize.The winner(s) acknowledge that JTI shall have no responsibility
                                for change or cancellation of the flight tickets and / or the accommodations redeemed using the vouchers and the winner(s) will not be entitled to any compensation.The Grand Prize may incur taxes according to the winner 's country of residence. The winner(s) is responsible for covering all such payments, taxes and duties. In case a winner refuses to pay associated taxes and duties, the Grand Prize will not be provided, and an alternate prize will not be awarded. <
                                /p> <
                                p >
                                3. The winner(s) by accepting these Terms and Conditions acknowledge that JTI or its agency will use the winner(s)’ personal data(including full name and email address) for the purpose of execution of the Grand Prize only, this may also include passing on the data by JTI to JTI’ s agency that handles the Grand Prize.The winner(s) consent to the collection, use, storage, and disclosure of his / her personal data by JTI
                                for the purposes set out above.JTI and / or JTI’ s agency will not keep personal data of the winners
                                for longer than it is necessary
                                for the execution of the Grand Prize.The winner(s) understand that they may revoke their consent at any time.The Grand Prizes will be forfeited
                                if the winner(s) provides inaccurate personal data(including full name and email address) that results in failure of execution of the Grand Prize. <
                                /p> <
                                p >
                                4. Employees of JTI, Gebrueder Heinemann, or Crit, the retail sales consultants, or any family members of a person that works directly or indirectly with them on organizing and running the Grand Prize are not eligible to be Participants. <
                                /p> <
                                p >
                                5. The Participants shall abide by all laws applicable in relation to the Grand Prize and shall indemnify and hold JTI harmless against any and all liabilities and claims incurred due to my violation of such applicable laws. <
                                /p> <
                                p >
                                6. The Participants acknowledge that the interest and good reputation of JTI is of paramount importance.JTI reserves the right to disqualify a Participant, or to withdraw the Grand Prize
                                if it believes the Participant is in breach of its obligations under these Terms and Conditions.The Participants shall not by act or omission, directly or indirectly bring JTI into disrepute. <
                                /p> <
                                p >
                                7. JTI reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, these Terms and Conditions with or without prior notice with no prior notice of its final and binding decision. <
                                /p> <
                                p >
                                8. JTI accepts no responsibility
                                for death, personal injury, loss, liabilities, injury or disappointment incurred or suffered by Participants because of entering the Grand Prize or accepting the Grand Prize. <
                                /p> <
                                p >
                                9. These Terms and Conditions shall be governed exclusively by the laws of Switzerland(without regard to any conflict of law rules that would cause the application of the laws of any other jurisdiction), to the exclusion of the United Nations Convention on the International Sale of Goods.Any dispute arising out of or in connection with these Terms and Conditions, whether of contractual or non - contractual nature, shall be subject to the exclusive jurisdiction of the courts of Geneva, Switzerland. <
                                /p> { /* ... other terms ... */ } <
                                /div>
                            )
                        } <
                        div className = "checkbox-container" >
                        <
                        input type = "checkbox"
                        id = "terms"
                        checked = { isChecked }
                        onChange = { handleCheckboxChange }
                        /> <
                        label htmlFor = "terms" > I agree to the terms and agreements < /label> <
                        /div> <
                        /div> <
                        div className = 'page-footer' >
                        <
                        button className = "page-button"
                        onClick = { handleSubmit } >
                        Submit <
                        /button> <
                        /div> <
                        img src = { float1 }
                        alt = "chat-ligth-1"
                        className = "floating-image chat-ligth-1" / >
                        <
                        img src = { float2 }
                        alt = "message-ligth-1"
                        className = "floating-image message-ligth-1" / >
                        <
                        img src = { float3 }
                        alt = "like-dark-1"
                        className = "floating-image like-dark-1" / >
                        <
                        img src = { float3 }
                        alt = "like-dark-2"
                        className = "floating-image like-dark-2" / >
                        <
                        img src = { float4 }
                        alt = "message-dark-1"
                        className = "floating-image message-dark-1" / >
                        <
                        /div>
                    );
                };

                export default PageThree;