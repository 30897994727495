import Dexie from 'dexie';

// Create a new Dexie database
const db = new Dexie('WinnersDatabase');

// Define the database schema
db.version(2).stores({
    winners: '++id, market_id, category_id, winning_datetime, is_claimable, is_claimed, claimed_at, created_at, updated_at',
    winnerContact: '++id, winner_id, first_name, last_name, email',
    results: '++id, winner_id, rating, selected_brands'
});

// Function to initialize the database
export const initializeDatabase = async () => {
    await db.open();
    console.log('Database initialized');
};

// Function to insert or update a winner
export const insertWinner = async (winner) => {
    try {
        await db.winners.put(winner);
        console.log('Winner inserted or updated:', winner);
    } catch (error) {
        console.error('Error inserting or updating winner:', error);
    }
};

// Function to fetch all winners
export const fetchWinners = async () => {
    try {
        const winners = await db.winners.toArray();
        return winners;
    } catch (error) {
        console.error('Error fetching winners:', error);
        return [];
    }
};

// Function to insert a winner contact and update the winner's is_claimable field
export const insertWinnerContact = async (contact) => {
    try {
        await db.transaction('rw', db.winners, db.winnerContact, async () => {
            await db.winnerContact.put(contact);
            const updateResult = await db.winners.update(contact.winner_id, { is_claimable: 0 });
            if (updateResult === 1) {
                console.log('Winner updated successfully:', contact.winner_id);
            } else {
                console.error('Winner update failed:', contact.winner_id);
            }
            console.log('Winner contact inserted:', contact);
        });
    } catch (error) {
        console.error('Error inserting winner contact:', error);
    }
};

// Function to fetch all winner contacts
export const fetchWinnerContacts = async () => {
    try {
        const contacts = await db.winnerContact.toArray();
        return contacts;
    } catch (error) {
        console.error('Error fetching winner contacts:', error);
        return [];
    }
};

// Function to clear the winnerContact table
export const clearWinnerContacts = async () => {
    try {
        await db.winnerContact.clear();
        console.log('Winner contacts cleared');
    } catch (error) {
        console.error('Error clearing winner contacts:', error);
    }
};

// Function to clear the winners table
export const clearWinners = async () => {
    try {
        await db.winners.clear();
        console.log('Winners cleared');
    } catch (error) {
        console.error('Error clearing winners:', error);
    }
};

// Function to fetch claimable winners
export const fetchClaimableWinners = async () => {
    try {
        const currentDateTime = new Date();
        const claimableWinners = await db.winners.where('is_claimable').equals(1)
            .filter(winner => new Date(winner.winning_datetime) < currentDateTime)
            .toArray();
        return claimableWinners;
    } catch (error) {
        console.error('Error fetching claimable winners:', error);
        return [];
    }
};

// Function to insert or update a result
export const insertResult = async (result) => {
    try {
        await db.results.put(result);
        console.log('Result inserted or updated:', result);
    } catch (error) {
        console.error('Error inserting or updating result:', error);
    }
};

// Function to fetch all results
export const fetchResults = async () => {
    try {
        const results = await db.results.toArray();
        return results;
    } catch (error) {
        console.error('Error fetching results:', error);
        return [];
    }
};

// Function to fetch result by winner_id
export const fetchResultByWinnerId = async (winner_id) => {
    try {
        const result = await db.results.where('winner_id').equals(winner_id).first();
        return result;
    } catch (error) {
        console.error('Error fetching result by winner_id:', error);
        return null;
    }
};

// Function to sync results with the server
export const syncResults = async (apiUrl, apiKey) => {
    try {
        const results = await fetchResults();
        for (const result of results) {
            const response = await fetch(`${apiUrl}/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify(result)
            });

            if (response.ok) {
                await db.results.delete(result.id);
                console.log(`Result with id ${result.id} synchronized and deleted locally.`);
            } else {
                console.error(`Failed to synchronize result with id ${result.id}:`, response.statusText);
            }
        }
    } catch (error) {
        console.error('Error synchronizing results:', error);
    }
};
// Function to clear the results table
export const clearResults = async () => {
    try {
        await db.results.clear();
        console.log('Results cleared');
    } catch (error) {
        console.error('Error clearing results:', error);
    }
};