import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const setContentSecurityPolicy = () => {
    const cspMetaTag = document.createElement('meta');
    cspMetaTag.setAttribute('http-equiv', 'Content-Security-Policy');
    cspMetaTag.setAttribute('content', "default-src 'self' data: http://localhost:8000 http://localhost:3000 http://localhost https://jti-backend.moyosa.dev https://yourholidaywithcamel.com;");
    document.head.appendChild(cspMetaTag);
};

const loadCordova = () => {
    if (window.cordova) {
        const cordovaScript = document.createElement('script');
        cordovaScript.setAttribute('src', 'cordova.js');
        cordovaScript.onload = () => {
            console.log('Cordova script loaded successfully');
            document.addEventListener('deviceready', onDeviceReady, false);
        };
        cordovaScript.onerror = () => {
            console.error('Failed to load Cordova script');
        };
        document.head.appendChild(cordovaScript);
    } else {
        console.log('Cordova not detected, skipping Cordova script loading');
        onDeviceReady();
    }
};

const onDeviceReady = () => {
    console.log('deviceready event fired');
    // Initialize React app after Cordova is ready
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render( <
        React.StrictMode >
        <
        App / >
        <
        /React.StrictMode>
    );

    // Register the service worker
    serviceWorker.register();
};

// Wait for the DOM to be fully loaded before including cordova.js
document.addEventListener('DOMContentLoaded', () => {
    console.log('DOMContentLoaded event fired');
    setContentSecurityPolicy();
    loadCordova();

    // If deviceready doesn't fire in a reasonable time, log an error
    setTimeout(() => {
        if (!window.cordova) {
            console.error('deviceready event did not fire within expected time');
        }
    }, 5000);
});