// src/services/sync.js
import axios from 'axios';
import { insertWinner, fetchWinners, fetchWinnerContacts, clearWinnerContacts, clearWinners, fetchResults, clearResults } from './database';
import localforage from 'localforage';

export const syncData = async() => {
    try {
        // Retrieve configuration data
        const apiUrl = await localforage.getItem('apiUrl');
        const apiKey = await localforage.getItem('apiKey');

        if (!apiUrl || !apiKey) {
            console.error('Configuration not set');
            return;
        }

        // Fetch local winner contacts
        console.log('Fetching local winner contacts...');
        const localContacts = await fetchWinnerContacts();
        console.log('Local winner contacts:', localContacts);

        // Push local winner contacts to the server if they exist
        if (localContacts.length > 0) {
            console.log('Pushing local winner contacts to the server...');
            const postResponse = await axios.post(`${apiUrl}/winnercontacts`, localContacts, {
                headers: {
                    'X-API-KEY': apiKey,
                },
            });
            if (postResponse.status === 200) {
                console.log('Local winner contacts pushed successfully');
                // Clear the winner contacts table
                await clearWinnerContacts();
            }
        }

        // Fetch local results
        console.log('Fetching local results...');
        const localResults = await fetchResults();
        console.log('Local results:', localResults);

        // Push local results to the server if they exist
        if (localResults.length > 0) {
            console.log('Pushing local results to the server...');
            const postResponse = await axios.post(`${apiUrl}/feedback`, localResults, {
                headers: {
                    'X-API-KEY': apiKey,
                },
            });
            if (postResponse.status === 200) {
                console.log('Local results pushed successfully');
                // Clear the results table
                await clearResults();
            }

        }

        // Fetch data from the server and update the winners table only if there are no local contacts or results
        if (localContacts.length === 0 && localResults.length === 0) {
            console.log('Fetching data from the server...');
            const response = await axios.get(`${apiUrl}/winners`, {
                headers: {
                    'X-API-KEY': apiKey,
                },
            });
            const serverData = response.data;
            console.log('Server data:', serverData);

            if (response.status === 200) {
                // Clear existing winners data
                console.log('Clearing existing winners data...');
                await clearWinners();

                // Insert server data into Dexie
                console.log('Inserting data into Dexie...');
                for (const item of serverData) {
                    try {
                        await insertWinner(item);
                    } catch (insertError) {
                        console.error('Error inserting winner:', item, insertError);
                    }
                }
                console.log('Insertion done.');
            }
        }
    } catch (error) {
        console.error('Error syncing data:', error);
    }
};

// Sync data when online
document.addEventListener('online', syncData, false);

// Periodic sync
setInterval(syncData, 60000); // Sync every minute