import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import localforage from 'localforage';
import { fetchWinners, fetchWinnerContacts, fetchResults, insertWinnerContact, clearWinnerContacts } from '../services/database';
import { syncData } from '../services/sync';
import './Admin.css'; // Import the CSS file

const Admin = () => {
  const navigate = useNavigate();
  const [apiUrl, setApiUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [winners, setWinners] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [results, setResults] = useState([]);
  const [spinSpeed, setSpinSpeed] = useState(10); // New state for spin speed
  const [reelRotations, setReelRotations] = useState(25); // New state for reel rotations
  const [debugMode, setDebugMode] = useState(false); // New state for debug mode
  const [alwaysWin, setAlwaysWin] = useState(false); // New state for always win
  const [termsVariant, setTermsVariant] = useState('variant1'); // New state for terms variant

  useEffect(() => {
    // Load the saved configuration
    const loadConfig = async () => {
      const savedApiUrl = await localforage.getItem('apiUrl');
      const savedApiKey = await localforage.getItem('apiKey');
      const savedSpinSpeed = await localforage.getItem('spinSpeed');
      const savedReelRotations = await localforage.getItem('reelRotations');
      const savedDebugMode = await localforage.getItem('debugMode');
      const savedAlwaysWin = await localforage.getItem('alwaysWin');
      const savedTermsVariant = await localforage.getItem('termsVariant'); // Load terms variant

      if (savedApiUrl) setApiUrl(savedApiUrl);
      if (savedApiKey) setApiKey(savedApiKey);
      if (savedSpinSpeed) setSpinSpeed(savedSpinSpeed);
      if (savedReelRotations) setReelRotations(savedReelRotations);
      if (savedDebugMode) setDebugMode(savedDebugMode);
      if (savedAlwaysWin) setAlwaysWin(savedAlwaysWin);
      if (savedTermsVariant) setTermsVariant(savedTermsVariant); // Set terms variant
    };

    loadConfig();

    // Fetch winners data
    const loadWinners = async () => {
      const winnersData = await fetchWinners();
      setWinners(winnersData);
    };

    // Fetch winner contacts data
    const loadContacts = async () => {
      const contactsData = await fetchWinnerContacts();
      setContacts(contactsData);
    };

    // Fetch results data
    const loadResults = async () => {
      const resultsData = await fetchResults();
      setResults(resultsData);
    };

    loadWinners();
    loadContacts();
    loadResults();
  }, []);

  const handleSave = async () => {
    await localforage.setItem('apiUrl', apiUrl);
    await localforage.setItem('apiKey', apiKey);
    await localforage.setItem('spinSpeed', spinSpeed);
    await localforage.setItem('reelRotations', reelRotations);
    await localforage.setItem('debugMode', debugMode);
    await localforage.setItem('alwaysWin', alwaysWin);
    await localforage.setItem('termsVariant', termsVariant); // Save terms variant
    alert('Configuration saved!');
  };

  const handleSync = async () => {
    await syncData();
    const winnersData = await fetchWinners();
    setWinners(winnersData);
    alert('Data synchronized!');
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <div className='page-container'>
      <div className="admin">
        <button className="back-button" onClick={handleBackToHome}>Back to Home</button>
        <h2>Admin Area</h2>
        <div>
          <label>
            API URL:
            <input 
              type="text" 
              value={apiUrl} 
              onChange={(e) => setApiUrl(e.target.value)} 
            />
          </label>
        </div>
        <div>
          <label>
            API Key:
            <input 
              type="text" 
              value={apiKey} 
              onChange={(e) => setApiKey(e.target.value)} 
            />
          </label>
        </div>
        <div>
          <label>
            Spin Speed:
            <input 
              type="number" 
              value={spinSpeed} 
              onChange={(e) => setSpinSpeed(e.target.value)} 
            />
          </label>
        </div>
        <div>
          <label>
            Reel Rotations:
            <input 
              type="number" 
              value={reelRotations} 
              onChange={(e) => setReelRotations(e.target.value)} 
            />
          </label>
        </div>
        <div>
          <label>
            Debug Mode:
            <input 
              type="checkbox" 
              checked={debugMode} 
              onChange={(e) => setDebugMode(e.target.checked)} 
            />
          </label>
        </div>
        {debugMode && (
          <div>
            <label>
              Always Win:
              <input 
                type="checkbox" 
                checked={alwaysWin} 
                onChange={(e) => setAlwaysWin(e.target.checked)} 
              />
            </label>
          </div>
        )}
        <div>
          <label>
            Terms Variant:
            <select value={termsVariant} onChange={(e) => setTermsVariant(e.target.value)}>
              <option value="variant1">Terms Casablanca</option>
              <option value="variant2">Terms Tunisia</option>
            </select>
          </label>
        </div>
        <button onClick={handleSave}>Save</button>
        <button onClick={handleSync}>Sync Data</button>
        <h2>Winners</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Market ID</th>
              <th>Category ID</th>
              <th>Winning DateTime</th>
              <th>Is Claimable</th>
              <th>Is Claimed</th>
              <th>Claimed At</th>
            </tr>
          </thead>
          <tbody>
            {winners.map((winner) => (
              <tr key={winner.id}>
                <td>{winner.id}</td>
                <td>{winner.market_id}</td>
                <td>{winner.category_id}</td>
                <td>{winner.winning_datetime}</td>
                <td>{winner.is_claimable}</td>
                <td>{winner.is_claimed}</td>
                <td>{winner.claimed_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>Winner Contacts</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Winner ID</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id}>
                <td>{contact.id}</td>
                <td>{contact.winner_id}</td>
                <td>{contact.first_name}</td>
                <td>{contact.last_name}</td>
                <td>{contact.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>Results</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Winner ID</th>
              <th>Rating</th>
              <th>Selected Brands</th>
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <tr key={result.id}>
                <td>{result.id}</td>
                <td>{result.winner_id}</td>
                <td>{result.rating}</td>
                <td>{result.selected_brands.join(', ')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;
